<template>
    <div class="block upnext" v-if="recipes">
        <div class="block-title">
            <h2>{{ $t("Recipes in this weekmenu") }}</h2>
        </div>
        <slider
            :navigation="true"
            :responsive="true"
            :space-between="10"
            :key="recipes.length"
            :pagination="false"
            :stepper="false">
            <template
                v-for="(recipe, index) in recipes"
                v-slot:[`slide-${index}`]
                :key="index">
                <up-next-card
                    :slug="recipe.getSlug()"
                    :title="recipe.getTitle()"
                    :image="recipe.getMedia('medium')" />
            </template>
        </slider>
    </div>
</template>

<script>
import Slider from "@/components/Slider";
import UpNextCard from "./UpNextCard.vue";

export default {
    components: {
        Slider,
        UpNextCard
    },
    props: {
        recipes: Array
    }
};
</script>

<style lang="scss">
    @import "index";
</style>

<template>
    <a
        :href="`/recepten/${slug}`"
        @click.prevent="$router.push({ path: `/recepten/${slug}` })"
        @mouseup="$router.push({ path: `/recepten/${slug}` })"
        class="up-next-card"
        :style="{ backgroundImage: `url(${image})` }"
    >
        <p>{{ title }}</p>
    </a>
</template>

<script>
export default {
    props: {
        slug: String,
        title: String,
        image: String
    }
};
</script>

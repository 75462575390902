<template>
    <div class="dashboard-weekmenu">
        <tab-switcher
            @arrowLeft="
                () => {
                    dayChange(activeDay - 1);

                    if (activeDay < 0) {
                        dayChange(6);
                    }
                }
            "
            @arrowRight="
                () => {
                    dayChange(activeDay + 1);

                    if (activeDay > 6) {
                        dayChange(0);
                    }
                }
            "
            @change="
                (selected_day) => {
                    dayChange(days.indexOf(selected_day));
                }
            "
            :show-title="true"
            :title="relativeDayTitle"
            :items="days"
            :active-item="days[activeDay]"
        />
        <kcal-switcher
            v-if="dayMenu"
            :daymenu="dayMenu"
            :planned-daymenu="plannedDayMenu"
            @change-kcal="$emit('change-kcal', $event)"
        >
            <template v-slot:actions>
                <div class="actions">
                    <favorite-button
                        :title="true"
                        :favorite-object="dayMenu"
                        :is-favorite="
                            favoriteDayMenuIds.includes(dayMenu.getId())
                        "
                    />
                </div>
            </template>
        </kcal-switcher>
    </div>
</template>

<script>
import { mapState } from "vuex";

import DateTime from "@/lib/misc/DateTime";
import FavoriteHelper from "@/lib/FavoriteHelper";
import TabSwitcher from "@/components/Tabswitcher";
import KcalSwitcher from "@/components/KcalSwitcher";
import FavoriteButton from "@/components/FavoriteButton";

export default {
    data() {
        return {
            activeDay: 0,
            days: [], // Contains days in shortform (mon, tue, wed, ...)
            days_full: [], // Contains days in full form (monday, tuesday, ...)
        };
    },
    props: {
        plannedMenus: Array,
        dayMenus: Array,
        relativeDays: Boolean,
    },
    components: {
        FavoriteButton,
        TabSwitcher,
        KcalSwitcher,
    },
    methods: {
        dayChange(dayIndex) {
            this.activeDay = dayIndex;

            this.$emit("activeDayName", this.days_full[this.activeDay]);
        },
    },
    computed: {
        ...mapState("favorites", ["favoriteDayMenuIds"]),
        relativeDayTitle() {
            if (!this.relativeDays) {
                return this.days_full[this.activeDay];
            }

            if (this.activeDay === 0) {
                return this.$t("Today");
            }

            if (this.activeDay === 1) {
                return this.$t("Tomorrow");
            }

            if (this.activeDay === 2) {
                return this.$t("Day after tomorrow");
            }

            return this.days_full[this.activeDay];
        },
        dayMenu() {
            return this.dayMenus[this.activeDay];
        },
        plannedDayMenu() {
            if (
                !this.plannedMenus ||
                !this.plannedMenus[this.activeDay] ||
                this.dayMenu.getId() ===
                    this.plannedMenus[this.activeDay].getId()
            ) {
                return null;
            }

            return this.plannedMenus[this.activeDay];
        },
    },
    async mounted() {
        if (this.relativeDays) {
            this.days = DateTime.GetNextDays(7).map((i) =>
                DateTime.GetShortDayName(i.getDay())
            );

            this.days_full = DateTime.GetNextDays(7).map((i) =>
                DateTime.GetDayName(i.getDay())
            );
        } else {
            // new Date(0,0,1) is the first monday in existance
            this.days = DateTime.GetNextDays(7, new Date(0, 0, 1)).map((i) =>
                DateTime.GetShortDayName(i.getDay())
            );

            this.days_full = DateTime.GetNextDays(7, new Date(0, 0, 1)).map(
                (i) => DateTime.GetDayName(i.getDay())
            );

            this.activeDay = new Date().getDay() - 1;
            if (this.activeDay < 0) { // Sunday
                this.activeDay += 7;
            }
        }

        if (!FavoriteHelper.hasDayMenu) {
            await FavoriteHelper.initDayMenus();
        }
    },
};
</script>

<template>
    <div class="kcal-switcher">
        <div class="kcal-switcher-header">
            <label v-if="planned_menus && plannedDaymenu">{{
                $t("Planned daymenu by you")
            }}</label>
            <label v-else>{{ $t("Daymenu") }}</label>
            <h2>{{ dayMenu.getTitle() }}</h2>
        </div>

        <div class="planned-toggler" v-if="plannedDaymenu">
            <div class="flex justify-between">
                <div class="column">
                    <router-link to="/my-pg#planned-daymenus">
                        {{ $t("View planned day menus") }}
                    </router-link>
                </div>
                <div class="column">
                    <input
                        type="checkbox"
                        id="planned_own_daymenu"
                        v-model="planned_menus"
                    />
                    <label for="planned_own_daymenu">
                        {{ $t("Show own daymenu") }}
                        <div class="toggler"></div>
                    </label>
                </div>
            </div>
        </div>

        <tab-switcher
            @change="
                (tab) => {
                    calorieVariantIndex = dayMenu
                        .getDayMenuVariantKeys()
                        .map((calorie) => $t(`{num} kcal`, { num: calorie }))
                        .indexOf(tab);
                }
            "
            :items="
                dayMenu
                    .getDayMenuVariantKeys()
                    .map((i) => $t(`{num} kcal`, { num: i }))
            "
            :active-item="$t(`{num} kcal`, { num: activeCalorieVariantKey })"
        />

        <div class="tab-switcher-content">
            <div class="moments">
                <div
                    class="moment"
                    v-for="moment in activeCalorieVariant.getMoments()"
                    :key="moment.moment"
                >
                    <h4 class="moment-title">
                        {{ $t(moment.moment) }}
                        ({{ moment.kilocalories }} kcal)
                    </h4>
                    <cms-body class="moment-content" :html="moment.html" />
                </div>
            </div>

            <slot
                name="actions"
                :activeCalorieVariant="activeCalorieVariant"
            ></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TabSwitcher from "@/components/Tabswitcher";
import CmsBody from "@/components/CmsBody";

export default {
    components: {
        TabSwitcher,
        CmsBody,
    },
    data() {
        return {
            calorieVariantIndex: 0,
            planned_menus: true,
        };
    },
    props: {
        daymenu: Object,
        plannedDaymenu: Object,
        showDayMenuTitle: Boolean,
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
        ...mapState("user", ["current_user_promise"]),

        dayMenu() {
            if (this.planned_menus && this.plannedDaymenu) {
                return this.plannedDaymenu;
            }

            return this.daymenu;
        },
        activeCalorieVariantKey() {
            return this.dayMenu.getDayMenuVariantKeys()[
                this.calorieVariantIndex
            ];
        },
        activeCalorieVariant() {
            return this.dayMenu.getDayMenuVariantByCalories(
                this.activeCalorieVariantKey
            );
        },
    },
    mounted() {
        // Default calories based on customer setting
        this.setDefaultCalorieVariant();
    },
    methods: {
        async setDefaultCalorieVariant() {
            await this.current_user_promise;

            let currentUser = this.getUser;
            if (currentUser === null) {
                return;
            }

            let defaultCalories = currentUser.getCustomerSetting("default_calories");
            if (defaultCalories === null) {
                return;
            }

            if (defaultCalories.value) {
                this.calorieVariantIndex = this.dayMenu
                    .getDayMenuVariantKeys()
                    .indexOf(defaultCalories.value);
            }
        }
    },
    watch: {
        calorieVariantIndex: {
            handler() {
                this.$emit("change-kcal", this.activeCalorieVariantKey);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="scss">
@import "index";
</style>

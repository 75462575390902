<template>
    <button
        @click="onFavoriteToggle"
        name="favbutton"
        class="fav btn"
        :class="[isFavorite ? 'toggled' : '', title ? '-icon -gray' : '-icon-only']"
        >
            <iconHeartFilled v-if="isFavorite" />
            <iconHeart v-else />
            <span v-if="title">{{ $t("Save as favorite") }}</span>
        </button>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import FavoriteHelper from "@/lib/FavoriteHelper";

import iconHeart from "@/assets/icons/icon-heart.svg?inline";
import iconHeartFilled from "@/assets/icons/icon-heart-filled.svg?inline";

export default {
    name: "FavoriteButton",
    components: {
        iconHeart,
        iconHeartFilled
    },
    data() {
        return {
            user: {},
        };
    },
    props: {
        favoriteObject: Object,
        isFavorite: Boolean,
        title: Boolean,
    },
    computed: {
        ...mapGetters("user", ["getUser"]),
    },
    methods: {
        ...mapActions("favorites", ["removeFavoriteRecipe", "addFavoriteRecipe", "setFavoriteRecipeIds"]),
        async onFavoriteToggle() {
            FavoriteHelper.onFavoriteToggle(this.favoriteObject);
        },
    },
};
</script>

<style lang="scss">
    @import "index";
</style>
